import React, { useEffect, useState } from "react";
import { useTheme } from "../contexts/MyThemeProvider";
import ToggleButton from "./ToggleButton";

interface ItemProps {
  size?: "small" | "large" | undefined;
}
type ThemeStyle = string | null | undefined;

const ToggleTheme: React.FC<ItemProps> = ({ size }) => {
  const themeCtx = useTheme();
  const [stylePath, setStylePath] = useState<ThemeStyle>();
  const handleOn = () => {
    setStylePath("dark.css");
    themeCtx.setTheme("dark");
  };

  const handleOff = () => {
    setStylePath("light.css");
    themeCtx.setTheme("light");
  };

  // window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (e) => {
  //   if (e.matches) setStylePath("dark.css");
  //   else setStylePath("light.css");
  // });

  useEffect(() => {
    setStylePath(`${localStorage.getItem("theme")}.css`);
  }, []);

  useEffect(() => {
    if (!stylePath) return;
    let link: HTMLLinkElement = document.getElementById("theme-id")! as HTMLLinkElement;
    link.href = stylePath!;
  }, [stylePath]);

  return (
    <>
      {stylePath && (
        <ToggleButton
          defaultChecked={stylePath!.split(".")[0] === "dark"}
          handleOn={handleOn}
          handleOff={handleOff}
          size={size}
          sliderBackColor={stylePath!.split(".")[0] === "light" ? "#f44336" : ""}
          buttonBackgroundColor={stylePath!.split(".")[0] === "light" ? "#f44336" : "#4caf50"}
        />
      )}
    </>
  );
};
export default ToggleTheme;
