import classes from "./ToggleButton.module.css";

interface ToggleProps {
  handleOn: () => void;
  handleOff: () => void;
  defaultChecked: boolean;
  size?: "small" | "large";
  buttonBackgroundColor?: string;
  sliderBackColor?: string;
  sliderColor?: string;
  sliderColorBefore?: string;
}

export default function ToggleButton(props: ToggleProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      props.handleOn();
    } else {
      props.handleOff();
    }
  };

  return (
    <label className={`${classes.container} + ${props.size && classes[props.size]}`}>
      <input
        defaultChecked={props.defaultChecked}
        type="checkbox"
        onChange={handleChange}
      />
      <span
        className={classes.slider}
        style={{
          ["--buttonBackColor" as string]: props.buttonBackgroundColor,
          ["--sliderBackColor" as string]: props.sliderBackColor,
          ["--sliderColor" as string]: props.sliderColor,
          ["--sliderColorBefore" as string]: props.sliderColorBefore,
        }}
      ></span>
    </label>
  );
}
