import { useMemo, useState } from "react";
import classes from "./Sidebar.module.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router";
import ToggleTheme from "./ToggleTheme";
import { useAuth } from "../contexts/AuthProvider";
import { SvgIconTypeMap, Tooltip, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/types";
import DashboardIcon from '@mui/icons-material/Dashboard';
import HomeIcon from "@mui/icons-material/Home";
// import ShowChartIcon from "@mui/icons-material/ShowChart";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import { Link } from "react-router-dom";

type StyledMUIIcon = OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
  muiName: string;
};

interface SidebarItemInterface {
  id?: string;
  open?: boolean;
  icon: StyledMUIIcon | any;
  label: string;
  linkTo: string;
  onClick?: React.MouseEventHandler | undefined;
  color?: string;
  htmlColor?: string;
}

// function TestIcon(props: any) {
//   return (
//     <SvgIcon {...props}>
//       <path d="M6,16.5L3,19.44V11H6M11,14.66L9.43,13.32L8,14.64V7H11M16,13L13,16V3H16M18.81,12.81L17,11H22V16L20.21,14.21L13,21.36L9.53,18.34L5.75,22H3L9.47,15.66L13,18.64" />
//     </SvgIcon>
//   );
// }

export default function Sidebar(): JSX.Element {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();

  const handleChevronClick = () => {
    setOpen((prev) => !prev);
  };

  const handleLogout = async () => {
    await logout();
    navigate("/login" /* { replace: true }*/);
  };

  const SidebarItems: SidebarItemInterface[] = useMemo(
    () => [
      {
        id: 'sidebar-item-1',
        icon: HomeIcon,
        label: "Home",
        linkTo: "/",
      },
      {
        id: 'sidebar-item-2',
        icon: SsidChartIcon,
        label: "Stocks",
        // htmlColor: "#6879D4",
        // htmlColor: "#7289DA",
        linkTo: "/stocks",
      },
      {
        id: 'sidebar-item-3',
        icon: DashboardIcon,
        label: "Dashboard",
        // htmlColor: "#6879D4",
        // htmlColor: "#7289DA",
        linkTo: "/dashboard",
      },
    ],
    []
  );

  const chevron = open ? (
    <ChevronLeftIcon style={{ fontSize: 32 }} className={`${classes.chevron}`} onClick={handleChevronClick} />
  ) : (
    <ChevronRightIcon
      style={{ fontSize: 32 }}
      className={`${classes.chevron}`}
      onClick={handleChevronClick}
    />
  );

  return (
    <div className={`${classes.container} ${open && classes.open}`}>
      <div className={`${classes.chevron_box}`}>{chevron}</div>
      <div className={classes.divider}></div>
      <div className={classes.section}>
        {SidebarItems.map((el) => {
          return (
            <SiderbarItem
              key={el.id}
              icon={el.icon}
              open={open}
              linkTo={el.linkTo}
              label={el.label}
              color={el.color}
              htmlColor={el.htmlColor}
            />
          );
        })}
      </div>
      <div className={classes.divider}></div>
      <div className={classes.section}>
        {currentUser ? (
          <SiderbarItem
            icon={LogoutIcon}
            open={open}
            onClick={handleLogout}
            linkTo="#"
            label="Log out"
            color="#f44336"
          />
        ) : (
          <SiderbarItem icon={PersonIcon} open={open} linkTo="/login" label="Log in" />
        )}
        {open && (
          <div
            className={`${classes.full_width} ${classes.slide_in}`}
            style={{ display: "flex", alignItems: "center" }}
          >
            <ToggleTheme size="small" />
            <Typography style={{ marginLeft: 6 }}>Dark mode</Typography>
          </div>
        )}
      </div>
    </div>
  );
}

const SiderbarItem: React.FC<SidebarItemInterface> = (props) => {
  const { open, icon: Icon, label, linkTo, color, htmlColor, onClick } = props;
  return (
    <div className={classes.item_container}>
      {open ? (
        <Link to={linkTo} onClick={onClick} className={`${classes.item_main} button-link`}>
          <Icon style={{ fontSize: 32, color: htmlColor || color }} className={classes.item_icon_open} />
          <Typography className={`${classes.item_label} ${classes.fade_in}`}>{label}</Typography>
        </Link>
      ) : (
        <Link to={linkTo} onClick={onClick} className={`button-link`}>
          <Tooltip title={label} placement="right" arrow>
            <Icon
              style={{ fontSize: 32, backgroundColor: color }}
              className={classes.item_icon}
              htmlColor={htmlColor}
            />
          </Tooltip>
        </Link>
      )}
    </div>
  );
};
