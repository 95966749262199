import React, { useContext, useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  User,
} from "firebase/auth";
import { auth } from "../firebase";

export interface AuthContextInterface {
  currentUser: User | undefined;
  login: (email: string, password: string) => void;
  signup: (email: string, password: string) => void;
  logout: () => void;
}

const AuthContext = React.createContext<AuthContextInterface>({
  currentUser: undefined,
  login: (_email, _password) => {},
  signup: (_email, _password) => {},
  logout: () => {},
  //   resetPassword: (email) => {},
  //   updateEmail: (emai) => {},
  //   updatePassword: (password) => {},
});

export function useAuth() {
  return useContext(AuthContext);
}

type Props = {
  children?: React.ReactNode
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User>();
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user as User);
      setAuthLoading(false);
    });
    return unsubscribe; // unsubscribe from listener when we unmount the component
  }, []);

  function signup(email: string, password: string) {
    return createUserWithEmailAndPassword(auth, email, password); // change this if I want to use my own server
  }

  //   function updateUserProfile(displayName) {
  //     const auth = getAuth();

  //     return updateProfile(auth.currentUser, {
  //       displayName,
  //     });
  //   }

  function login(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password); // change this if I want to use my own server
  }

  function logout() {
    return signOut(auth);
  }

  function resetPassword(email: string) {
    return sendPasswordResetEmail(auth, email);
  }

  //   function updateEmail(email: string) {
  //     return currentUser!.updateEmail(email);
  //   }

  //   function updatePassword(password: string) {
  //     return currentUser!.updatePassword(password);
  //   }

  // values exporting to children below
  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    // updateEmail,
    // updatePassword,
    // updateUserProfile,
  };

  return <AuthContext.Provider value={value}>{!authLoading && children}</AuthContext.Provider>;
};
