import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./contexts/AuthProvider";
import MyThemeProvider from "./contexts/MyThemeProvider";
import "./index.css";

const container = document.getElementById("root")!;
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <AuthProvider>
      <MyThemeProvider>
        <App />
      </MyThemeProvider>
    </AuthProvider>
  </React.StrictMode>
);
