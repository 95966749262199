import React, { useContext, useEffect, useState } from "react";
import getThemePreference from "../Helpers/getThemePreference";

export type Theme = "dark" | "light";

export interface ThemeInterface {
  theme: Theme;
  setTheme: (newTheme: Theme) => void;
}

const ThemeContext = React.createContext<ThemeInterface>({
  theme: getThemePreference(),
  setTheme: () => {},
});

export function useTheme() {
  return useContext(ThemeContext);
}

type Props = {
  children?: React.ReactNode
}

const MyThemeProvider: React.FC<Props> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(getThemePreference());

  useEffect(() => {
    let themePreference: Theme = getThemePreference();
    if (themePreference) {
      localStorage.setItem("theme", themePreference);
      let link: HTMLLinkElement = document.getElementById("theme-id")! as HTMLLinkElement;
      link.href = `${themePreference}.css`;
    }
  }, []);

  const changeTheme = (newTheme: Theme) => {
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  const value: ThemeInterface = {
    theme,
    setTheme: changeTheme,
  };

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

export default MyThemeProvider;
