
const getThemePreference: Function = (): string | null => {
  let themePreference: string | null = localStorage.getItem("theme");
  if (window.matchMedia && themePreference === null) {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) themePreference = "dark";
    else if (window.matchMedia("(prefers-color-scheme: light)").matches) themePreference = "light";
    else themePreference = "dark"; // if there is no preference
  };
  return themePreference;
};

export default getThemePreference;