import React, { useCallback, lazy, Suspense } from "react";
import Sidebar from "./components/Sidebar";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import classes from "./App.module.css";
import { useAuth } from "./contexts/AuthProvider";
import { Navigate } from "react-router-dom";
import { PaletteMode, Paper } from "@mui/material";
import { useTheme } from "./contexts/MyThemeProvider";
import LinearLoading from "./components/LinearLoading";

const Home = lazy(() => import("./Home/Home"));
const Stocks = lazy(() => import("./Stocks/Stocks"));
const Login = lazy(() => import("./Auth/Login"));
const Dashboard = lazy(() => import("./Dashboard/Dashboard"));

const myFallback = () => {
  return <LinearLoading />;
};

export const currencySign = "€";

const App = (): JSX.Element => {
  const { currentUser } = useAuth();
  const themeCtx = useTheme();
  const theme = createTheme({
    palette: {
      mode: themeCtx.theme as PaletteMode,
    },
  });

  const SharedComponents: React.FC = useCallback(() => {
    return (
      <>
        <Sidebar />
      </>
    );
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Paper className={classes.container} sx={{ backgroundColor: "var(--backgorund)" }} elevation={0}>
        <Suspense fallback={myFallback()}>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<>{currentUser ? <Navigate to="/" replace /> : <Login />}</>} />
              <Route path="*" element={<SharedComponents />} />
            </Routes>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/stocks" element={<Stocks />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/login" />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </Paper>
    </ThemeProvider>
  );
};

export default App;
